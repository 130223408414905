import React from 'react';

const Attributions = () => {
    return (
        <div className="flex flex-col justify-center space-x-8">
           <h1>Attributions</h1><br></br>
            <ul>
                <li><a href="https://www.vecteezy.com/free-vector/management">Management Vectors by Vecteezy</a></li>
                <li><a href="https://fontawesome.com/">Icons by FontAwesome</a></li>
            </ul>
        </div>
    );
};

export default Attributions;
