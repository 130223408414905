import React from 'react'

import { auth } from '../firebaseConfig'

import { Navigate } from 'react-router-dom'

function Logout() {
    // logout firebase user 
    // redirect to login page

    auth.signOut().then(() => {
        // Sign-out successful.
    }).catch((error) => {
        console.error(error)
    })

    return (
        <div>
            <Navigate to="/auth" />
        </div>
    )
}

export default Logout