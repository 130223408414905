import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar';
import { auth } from '../firebaseConfig';
import { supabase } from '../supabaseClient';

function Dashboard() {
  const [user, setUser] = useState(null);
  const [bugs, setBugs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [org, setOrg] = useState([]);
  const [showDesktopMessage, setShowDesktopMessage] = useState(false);
  const [subscriptionTier, setSubscriptionTier] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      const fetchOrgs = async () => {
        try {
          const { data: orgsData, error } = await supabase
              .from('orgs')
              .select('*');
          if (error) throw error;

          const membersData = await Promise.all(orgsData.map(async org => {
            const { data: orgMembers, error: membersError } = await supabase
                .from('orgMembers')
                .select('*')
                .eq('orgID', org.id);
            if (membersError) throw membersError;

            return { orgId: org.id, members: orgMembers };
          }));

          setOrg(orgsData);

          return membersData;
        } catch (error) {
          console.error('Error fetching organizations: ', error);
          return null;
        }
      };

      const fetchData = async () => {
        try {
          const orgsData = await fetchOrgs();
          if (orgsData) {
            await fetchBugs(orgsData);
            setLoading(false);
          }
        } catch (error) {
          console.error('Error fetching data: ', error);
        }
      };

      const fetchSubscription = async () => {
        try {
          const { data: subscriptionData, error } = await supabase
              .from('users')
              .select('membershipType')
              .eq('UID', user.uid)
              .single();
          if (error) throw error;
          setSubscriptionTier(subscriptionData.membershipType);
        } catch (error) {
          console.error('Error fetching subscription: ', error);
        }
      };

      fetchData();
      fetchSubscription();
    }
  }, [user]);

  const fetchBugs = async (orgsData) => {
    try {
      const bugsData = [];

      for (const orgData of orgsData) {

        if (!user || !user.uid) {
          console.error('User object is undefined or does not contain UID.');
          continue;
        }

        const isMemberOfOrg = orgData.members.some(member => member.memberID === user.uid);
        if (!isMemberOfOrg) {
          continue;
        }

        const { data: orgBugs, error } = await supabase
            .from('bugs')
            .select('*')
            .eq('orgID', orgData.id);
        if (error) throw error;

        bugsData.push(...orgBugs);
      }
      setBugs(bugsData);
    } catch (error) {
      console.error('Error fetching bugs: ', error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setShowDesktopMessage(window.innerWidth < 650);
    };

    if (window.innerWidth < 650) {
      setShowDesktopMessage(true);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!user) {
    return (
        <div className='dashboard bg-dark-main h-screen'>
          <NavBar />
          <div className="mx-auto p-4 mt-12 container">
            <h1 className="text-3xl font-bold mb-4 text-white-default">Dashboard</h1>
            <p className="text-white-default">You must be logged in to see this page...</p>
          </div>
        </div>
    );
  }

  if (showDesktopMessage) {
    return (
        <div className='dashboard bg-dark-main h-screen'>
          <NavBar />
          <div className="mx-auto p-4 mt-12 container">
            <h1 className="text-white-default text-xl">This page is not available on mobile devices. Please use a desktop or laptop to view this page.</h1>
          </div>
        </div>
    );
  }

  return (
      <div className='dashboard bg-dark-main h-screen transition ease-in-out duration-300'>
        <NavBar />
        <div className="mx-auto p-4 mt-12 container">
          <h1 className="text-3xl font-bold mb-4 text-white-default">Dashboard</h1>
          {subscriptionTier === 'free' && (
              <div className="bg-red-600 text-white-default p-4 rounded-md mb-4">
                You are on the free tier. <a href="/subscribe" className="underline">Upgrade now</a> for more features!
              </div>
          )}
          <div className="mt-16 container mx-auto p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"></div>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="shadow-lg rounded-md p-6 bg-card-default flex flex-col justify-center items-center">
                <h2 className="text-xl font-semibold mb-2 text-white-default bg-gray-400 h-6 w-3/4 rounded-md text-center">Bugs Created:</h2>
                <h2 className="text-xl font-semibold mb-2 text-white-default bg-gray-400 h-6 w-3/4 rounded-md text-center truncate">
                  {bugs.filter(bug => bug.bugAuthor === user.uid).length}
                </h2>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="shadow-lg rounded-md p-6 bg-card-default flex flex-col justify-center items-center">
                <h2 className="text-xl font-semibold mb-2 text-white-default bg-gray-400 h-6 w-3/4 rounded-md text-center">Bugs Open:</h2>
                <h2 className="text-xl font-semibold mb-2 text-white-default bg-gray-400 h-6 w-3/4 rounded-md text-center truncate">
                  {bugs.filter(bug => (bug.isOpen && bug.bugAuthor === user.uid) || (bug.isOpen && bug.assignedMembers.includes(user.uid))).length}
                </h2>
              </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="shadow-lg rounded-md p-6 bg-card-default flex flex-col justify-center items-center">
                <h2 className="text-xl font-semibold mb-2 text-white-default bg-gray-400 h-6 w-3/4 rounded-md text-center">Assigned Bugs:</h2>
                <h2 className="text-xl font-semibold mb-2 text-white-default bg-gray-400 h-6 w-3/4 rounded-md text-center truncate">
                  {bugs.filter(bug => bug.assignedMembers.includes(user.uid)).length}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Dashboard;
