import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig'; // Firebase auth
import { supabase } from '../supabaseClient'; // Supabase client
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import NavBar from '../NavBar';

function Organizations() {
    const [user, setUser] = useState(null);
    const [orgsData, setOrgsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalJoinOpen, setModalJoinOpen] = useState(false);
    const [orgName, setOrgName] = useState('');
    const [orgDesc, setOrgDesc] = useState('');
    const [orgJoinCode, setOrgJoinCode] = useState('');
    const [orgOwner, setOrgOwner] = useState('');
    const [showDesktopMessage, setShowDesktopMessage] = useState(false);

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '400px',
            padding: '2rem',
            backgroundColor: '#37474f',
            color: '#f7fafc',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            border: '1px solid #1a202c',
            outline: `1px solid #000000`, // Added outline style
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
            if (user) {
                fetchOrgsData(user.uid);
            } else {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setShowDesktopMessage(window.innerWidth < 650);
        };

        // Check if the user is on a mobile device
        if (window.innerWidth < 650) {
            setShowDesktopMessage(true);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchOrgsData = async (userId) => {
        try {
            const { data: orgs, error } = await supabase
                .from('orgs')
                .select('*')
                .order('orgName', { ascending: true });

            if (error) {
                console.error('Error fetching organizations:', error.message);
                return;
            }

            const orgsWithMembers = await Promise.all(orgs.map(async (org) => {
                const { data: orgMembers, error: memberError } = await supabase
                    .from('orgMembers')
                    .select('*')
                    .eq('orgID', org.id)
                    .eq('memberID', userId);

                if (memberError) {
                    console.error('Error fetching org members:', memberError.message);
                    return null;
                }

                // Fetch bugs associated with the organization
                const { data: bugsData, error: bugsError } = await supabase
                    .from('bugs')
                    .select('*')
                    .eq('orgID', org.id);

                if (bugsError) {
                    console.error('Error fetching bugs:', bugsError.message);
                    return null;
                }

                return {
                    ...org,
                    membersData: orgMembers,
                    bugsData: bugsData,
                };
            }));

            const filteredOrgs = orgsWithMembers.filter(org => org && org.membersData.length > 0);
            setOrgsData(filteredOrgs);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const afterOpenModal = () => {
        // Any actions after modal opens
    };

    const openJoinModal = () => {
        setModalJoinOpen(true);
    };

    const closeModalJoin = () => {
        setModalJoinOpen(false);
    };

    const afterOpenJoinModal = () => {
        // Any actions after join modal opens
    };

    const handleCreateOrg = async () => {
        try {
            if (orgName.trim() !== '') {

                const { data: newOrg, error: createOrgError } = await supabase
                    .from('orgs')
                    .insert([
                        {
                            orgName: orgName.trim(),
                            orgDesc: orgDesc.trim(),
                            joinCode: Math.random().toString(36).substr(2, 8), // Generate a simple join code
                            orgOwner: user.uid,
                            createdDate: new Date().toLocaleDateString('en-US', options),
                            isPrivate: true,
                        }
                    ])
                    .select()
                    .single();

                if (createOrgError) {
                    console.error('Error creating organization:', createOrgError.message);
                    return;
                }


                if (!newOrg || !newOrg.id) {
                    console.error('No organization created or missing organization ID.');
                    return;
                }

                const { error: memberInsertError } = await supabase.from('orgMembers').insert([
                    {
                        orgID: newOrg.id,
                        memberID: user.uid,
                        memberName: user.displayName,
                        memberEmail: user.email,
                        memberRole: 'Owner',
                        joinedDate: new Date().toLocaleDateString('en-US', options),
                        profilePicture: user.photoURL,
                    }
                ]);

                if (memberInsertError) {
                    console.error('Error adding user to organization members:', memberInsertError.message);
                    return;
                }

                setOrgName('');
                setOrgDesc('');
                closeModal();

                // Fetch the updated organizations data after creating a new organization
                fetchOrgsData(user.uid);
            }
        } catch (error) {
            console.error('Error handling create organization:', error);
        }
    };


    const handleJoinOrg = async () => {
        try {
            if (orgJoinCode.trim() !== '') {
                const { data: orgToJoin, error: joinOrgError } = await supabase
                    .from('orgs')
                    .select('*')
                    .eq('joinCode', orgJoinCode.trim())

                    .single();

                if (joinOrgError) {
                    console.error('Error joining organization:', joinOrgError.message);
                    return;
                }

                if (!orgToJoin) {
                    console.error('Organization with the given join code not found');
                    return;
                }

                // Check if the user is already a member of the organization
                const { data: existingMember, error: existingMemberError } = await supabase
                    .from('orgMembers')
                    .select('*')
                    .eq('orgID', orgToJoin.id)
                    .eq('memberID', user.uid)
                    .single();

                if (existingMember) {
                    console.log('User is already a member of this organization');
                    return;
                }

                // Add the user as a member of the organization in the 'orgMembers' table
                await supabase.from('orgMembers').insert([
                    {
                        orgID: orgToJoin.id,
                        memberID: user.uid,
                        memberName: user.displayName,
                        memberEmail: user.email,
                        memberRole: 'Member',
                        joinedDate: new Date().toLocaleDateString('en-US', options),
                        profilePicture: user.photoURL,
                    },
                ]);

                setOrgJoinCode('');
                closeModalJoin();

                // Fetch the updated organizations data after joining an organization
                fetchOrgsData(user.uid);
            }
        } catch (error) {
            console.error('Error handling join organization:', error.message);
        }
    };

    if (!user) {
        return (
            <div className="dashboard bg-dark-main h-full">
                <NavBar />
                <div className="mx-auto p-4 mt-12 container">
                    <p className="text-white-default mb-12">Please log in to view this page.</p>
                    <Link to="/auth" className="text-white-default hover:text-dark-200 p-2 rounded-lg bg-blue hover:bg-blue-600 px-4 py-2 rounded-lg text-sm">
                        Login
                    </Link>
                </div>
            </div>
        );
    }

    if (showDesktopMessage) {
        return (
            <div className='dashboard bg-dark-main h-screen'>
                <NavBar />
                <div className="mx-auto p-4 mt-12 container">
                    <h1 className="text-white-default text-xl">This page is not available on mobile devices. Please use a desktop or laptop to view this page.</h1>
                </div>
            </div>
        );
    }

    return (
        <div className="dashboard bg-dark-main h-screen">
            <NavBar />
            <div className="p-4 pl-10 pr-10 bg-dark-main">
                <h1 className="text-3xl font-bold mb-4 text-white-default">Options:</h1>

                <div className="flex bg-dark-main flex-wrap -mx-4">
                    <div className="w-full md:w-1/2 lg:w-1/3 p-4">
                        <div>
                            <div className="bg-card-default cursor-pointer p-6 rounded-lg h-18 shadow-lg ease-in-out duration-300 hover:bg-card-dark select-none" onClick={openModal}>
                                <h2 className="text-xl font-bold mb-4 text-white-default">Create Organization</h2>
                                <p className="text-white-default">Create a new organization to start tracking bugs.</p>
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 lg:w-1/3 p-4 ">
                        <div>
                            <div className="bg-card-default cursor-pointer p-6 rounded-lg h-18 shadow-lg ease-in-out duration-300 hover:bg-card-dark select-none" onClick={openJoinModal}>
                                <h2 className="text-xl font-bold mb-4 text-white-default">Join Organization</h2>
                                <p className="text-white-default">Join an organization using a join code.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <h1 className="text-3xl font-bold mb-4 text-white-default">Organizations:</h1>
                {orgsData.length === 0 && (
                    <p className="text-white-default">No organizations found.</p>
                )}

                <div className="flex flex-wrap -mx-4 bg-dark-main h-1/3">
                    {orgsData.map((org, index) => (
                        <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-4">
                            <Link to={`/organizationDisplay/${org.id}`}>
                                <div className="bg-card-default cursor-pointer p-6 rounded-lg shadow-lg ease-in-out duration-300 hover:bg-card-dark select-none">
                                    <h2 className="text-xl font-bold mb-4 text-white-default">{org.orgName}</h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                                        <p className="text-white-default">Members: {org.membersData.length}</p>
                                        <p className="text-white-default">Bugs: {org.bugsData.length}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>

                {/* Create Organization Modal */}
                <Modal
                    isOpen={modalOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    contentLabel="Create Organization Modal"
                >
                    <h2 className="text-lg font-semibold mb-4 text-gray-800">Create Organization</h2>
                    <button onClick={closeModal} className="absolute top-2 right-2 text-gray-600 hover:text-gray">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div>
                        <label htmlFor="orgName" className="block text-sm font-medium text-gray-700">Organization Name:</label>
                        <input
                            type="text"
                            id="orgName"
                            value={orgName}
                            onChange={(e) => setOrgName(e.target.value)}
                            className="block w-full outline outline-2 outline-purple p-3 mt-1 mb-5 bg-transparent rounded-md text-white-default focus:outline-text-lightPurple focus:border-none transition ease-in-out duration-300"
                            placeholder="Enter organization name"
                        />
                        <label htmlFor="orgName" className="block text-sm font-medium text-gray-700">Organization Description:</label>
                        <input
                            type="text"
                            id="orgDesc"
                            value={orgDesc}
                            onChange={(e) => setOrgDesc(e.target.value)}
                            className="block w-full outline outline-2 outline-purple p-3 mt-1 mb-5 bg-transparent rounded-md text-white-default focus:outline-text-lightPurple focus:border-none transition ease-in-out duration-300"
                            placeholder="Enter organization description"
                        />
                        <button onClick={handleCreateOrg} className="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Create Organization
                        </button>
                    </div>
                </Modal>

                {/* Join Organization using Code Modal */}
                <Modal
                    isOpen={modalJoinOpen}
                    onAfterOpen={afterOpenJoinModal}
                    onRequestClose={closeModalJoin}
                    style={customStyles}
                    ariaHideApp={false}
                    contentLabel="Join Organization Modal"
                >
                    <h2 className="text-lg font-semibold mb-4 text-gray-800">Join Organization</h2>
                    <button onClick={closeModalJoin} className="absolute top-2 right-2 text-gray-600 hover:text-gray">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div>
                        <label htmlFor="orgJoinCode" className="block text-sm font-medium text-gray-700">Join Code:</label>
                        <input
                            type="text"
                            id="orgJoinCode"
                            value={orgJoinCode}
                            onChange={(e) => setOrgJoinCode(e.target.value)}
                            className="block w-full outline outline-2 outline-purple p-3 mt-1 mb-5 bg-transparent rounded-md text-white-default focus:outline-text-lightPurple focus:border-none transition ease-in-out duration-300"
                            placeholder="Enter join code"
                        />
                        <button onClick={handleJoinOrg} className="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Join Organization
                        </button>
                    </div>
                </Modal>
            </div>
        </div>
    );
}

export default Organizations;
