import React, { useState, useImperativeHandle, forwardRef } from "react";
import notificationSound from "../audio/notifications/recieved.wav";
import negativeSound from "../audio/notifications/negative.mp3";

const Notifications = forwardRef((props, ref) => {
    const [notifications, setNotifications] = useState([]);

    const positiveAudio = new Audio(notificationSound);
    const negativeAudio = new Audio(negativeSound);

    useImperativeHandle(ref, () => ({
        showAlertMessage(message, isPositive) {
            const id = Date.now();
            const newNotification = {
                id,
                message,
                isPositive,
                alertClass: 'alert-pop-in',
                color: isPositive ? '#13ce66' : '#ff5e56'
            };

            if (isPositive) {
                positiveAudio.play();
            } else {
                negativeAudio.play();
            }

            setNotifications(prev => [...prev, newNotification]);

            setTimeout(() => {
                setNotifications(prev =>
                    prev.map(n =>
                        n.id === id ? { ...n, alertClass: 'alert-pop-out' } : n
                    )
                );
                setTimeout(() => {
                    setNotifications(prev => prev.filter(n => n.id !== id));
                }, 300);
            }, 3000);
        }
    }));

    return (
        <div className="fixed bottom-10 right-2 flex flex-col-reverse space-y-2 space-y-reverse">
            {notifications.map((notification, index) => (
                <div
                    key={notification.id}
                    className={`text-white-default px-4 py-2 rounded shadow-lg ${notification.alertClass}`}
                    style={{
                        backgroundColor: notification.color,
                        marginBottom: `${index + 10}px`
                    }}
                >
                    {notification.message}
                </div>
            ))}
        </div>
    );
});

export default Notifications;
