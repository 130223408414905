import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NavBar from '../NavBar';
import { supabase } from '../supabaseClient'; // Supabase configuration
import { auth } from '../firebaseConfig'; // Firebase configuration
import { TableIcon, UserGroupIcon, CogIcon, PlusIcon } from '@heroicons/react/solid';
import Modal from 'react-modal';
import Notification from '../components/Notifications';
import Notifications from "../components/Notifications";


function OrganizationDisplay() {
    const { orgID } = useParams();
    const navigate = useNavigate();
    const subtitleRef = useRef();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const orgColors = ['#0d2f81', '#0079bf', '#519839', '#61bd4f', '#d29034', '#f2d600', '#b04632'];

    const [user, setUser] = useState(null);
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [org, setOrg] = useState(null);
    const [orgMembers, setOrgMembers] = useState([]);
    const [activeTab, setActiveTab] = useState('projects');
    const [createProjectModalIsOpen, setCreateProjectModalIsOpen] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [showDesktopMessage, setShowDesktopMessage] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const [selectedRole, setSelectedRole] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertClass, setAlertClass] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const notificationRef = useRef();

    // Custom styles for Modal
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '400px',
            padding: '2rem',
            backgroundColor: '#37474f',
            color: '#f7fafc',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            border: '1px solid #1a202c',
            outline: '1px solid #000000',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
    };

    const fetchData = async () => {
        try {
            const { data: orgData, error: orgError } = await supabase
                .from('orgs')
                .select('*')
                .eq('id', orgID)
                .single();

            if (orgError) throw orgError;
            setOrg(orgData);

            const { data: orgMembersData, error: orgMembersError } = await supabase
                .from('orgMembers')
                .select('*')
                .eq('orgID', orgID);

            if (orgMembersError) throw orgMembersError;
            setOrgMembers(orgMembersData);

            const { data: projectsData, error: projectsError } = await supabase
                .from('projects')
                .select('*')
                .eq('orgID', orgID);

            if (projectsError) throw projectsError;
            setProjects(projectsData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setUser(user);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (user) {
            fetchData();
        }
    }, [user, orgID]);

    useEffect(() => {
        const handleResize = () => {
            setShowDesktopMessage(window.innerWidth < 650);
        };
        setShowDesktopMessage(window.innerWidth < 650);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                if (user) {
                    const { data: orgMembersData, error: orgMembersError } = await supabase
                        .from('orgMembers')
                        .select('memberRole')
                        .eq('orgID', orgID)
                        .eq('memberID', user.uid)
                        .single();

                    if (orgMembersError) throw orgMembersError;
                    setUserRole(orgMembersData.memberRole);
                }
            } catch (error) {
                console.error('Error fetching user role:', error);
            }
        };
        if (org && user) {
            fetchUserRole();
        }
    }, [org, user, orgID]);

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleUpdateRole = async (memberID, selectedRole) => {
        try {
           // Update the role of the selected member
            await supabase
                .from('orgMembers')
                .update({ memberRole: selectedRole })
                .eq('id', memberID);

            fetchData();
        } catch (error) {
            console.error('Error updating user role:', error);
        }
    };

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate('/login');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const generateNewApiKey = async () => {
        try {
            const newApiKey = Array.from({ length: 4 }, () =>
                Math.random().toString(36).substring(2, 8)
            ).join('-');

            await supabase
                .from('orgs')
                .update({ api_key: newApiKey })
                .eq('id', orgID);
            notificationRef.current.showAlertMessage('New API Key Generated!', true);
        } catch (error) {
            console.error('Error generating new API key:', error);
        }
    };

    const copyApiKey = async (apiKey) => {
        try {
            await navigator.clipboard.writeText(apiKey);
            notificationRef.current.showAlertMessage('Copied API Key To Clipboard.', true);
        } catch (error) {
            console.error('Failed to copy API key: ', error);
        }
    };


    const generateNewJoinCode = async () => {
        const joinCode = Math.random().toString(36).substring(2, 8);

        try {
            await supabase
                .from('orgs')
                .update({ joinCode })
                .eq('id', orgID);

            notificationRef.current.showAlertMessage('New Join Code Generated!', true);
        } catch (error) {
            console.error('Error generating new join code:', error);
        }
    };

    const copyJoinCode = async (joinCode) => {
        try {
            await navigator.clipboard.writeText(joinCode);
            notificationRef.current.showAlertMessage('Copied Join Code To Clipboard!', true);
        } catch (error) {
            console.error('Failed to copy Join Code: ', error);
        }
    };

    const handleCreateProject = async (e) => {
        e.preventDefault();
        try {
            const { error } = await supabase
                .from('projects')
                .insert([
                    {
                        name: projectName,
                        description: projectDescription,
                        orgID,
                        color: orgColors[Math.floor(Math.random() * orgColors.length)],
                        createdAt: new Date().toLocaleDateString('en-US', options),
                    }
                ]);

            if (error) throw error;

            setCreateProjectModalIsOpen(false);
            setProjectName('');
            setProjectDescription('');
            fetchData();
            notificationRef.current.showAlertMessage('Project created successfully!', true);
        } catch (error) {
            console.error('Error creating new project:', error);
        }
    };



    useEffect(() => {
        if (!orgID) return;

        const projectSubscription = supabase
            .channel(`public:projects:orgID=eq.${orgID}`)
            .on('postgres_changes', { event: '*', schema: 'public', table: 'projects' }, payload => {
                fetchData();
            })
            .subscribe();

        const orgMembersSubscription = supabase
            .channel(`public:orgMembers:orgID=eq.${orgID}`)
            .on('postgres_changes', { event: '*', schema: 'public', table: 'orgMembers' }, payload => {
                fetchData();
            })
            .subscribe();

        const orgSubscription = supabase
            .channel(`public:orgs:id=eq.${orgID}`)
            .on('postgres_changes', { event: '*', schema: 'public', table: 'orgs' }, payload => {
                fetchData();
            })
            .subscribe();

        return () => {
            supabase.removeChannel(projectSubscription);
            supabase.removeChannel(orgMembersSubscription);
            supabase.removeChannel(orgSubscription);
        };
    }, [orgID]);

    return (
        <div className='dashboard bg-dark-main h-full min-h-screen transition ease-in-out duration-300'>
            <NavBar />
            <div className="mx-auto p-4 mt-12 container md:w-full lg:w-3/4 xl:w-2/3 sm:w-full">
                <div className="flex items-center mb-4">
                    <h1 className="text-2xl font-bold text-white-default">{org ? org.orgName : 'Organization'}</h1>
                    {org && (
                        <div title={`This determines whether people outside the organisation can you view your projects.`} className={`ml-2 select-auto inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium text-gray border border-card-purple`}>
                            {org.isPrivate ? 'Private' : 'Public'}
                        </div>
                    )}
                </div>
                <hr></hr>
                <div className="mb-8">
                    <ul className="flex bg-dark-lightMain p-4 text-white-default justify-between w-full md:w-1/2 lg:w-1/3 rounded-md select-none space-x-4">
                        <li
                            className={`cursor-pointer inline-flex hover:text-dark-500 ${activeTab === 'projects' ? 'text-text-lightPurple font-bold underline select-none' : 'text-gray-500'} transition ease-in-out duration-100`}
                            onClick={() => setActiveTab('projects')}
                        >
                            <TableIcon className='h-6 w-5 mr-2' /> Projects
                        </li>
                        <li
                            className={`cursor-pointer inline-flex  hover:text-dark-500 ${activeTab === 'members' ? 'text-text-lightPurple font-bold underline select-none' : 'text-gray-500'}  transition ease-in-out duration-100`}
                            onClick={() => setActiveTab('members')}
                        >
                            <UserGroupIcon className='h-6 w-5 mr-2' /> Members
                        </li>
                        <li
                            className={`cursor-pointer inline-flex  hover:text-dark-500 ${activeTab === 'settings' ? 'text-text-lightPurple font-bold underline select-none' : 'text-gray-500'}  transition ease-in-out duration-100`}
                            onClick={() => setActiveTab('settings')}
                        >
                            <CogIcon className='h-6 w-5 mr-2' />
                            Settings
                        </li>
                    </ul>
                </div>

                <div className="bg-card-default rounded-lg shadow-lg p-6">
                    {activeTab === 'projects' && (
                        <>
                            <h2 className="text-xl font-semibold mb-4 text-white-default">Projects</h2>
                            <div className="mt-6">
                                {userRole === 'Owner' || userRole === 'Editor' ? (
                                    <button
                                        onClick={() => setCreateProjectModalIsOpen(true)}
                                        className="inline-flex mb-5 bg-purple hover:bg-card-purple text-white-default py-2 px-4 rounded transition ease-in-out duration-100"
                                    >
                                        <PlusIcon className='h-6 w-5 mr-1' />
                                        Create New Project
                                    </button>
                                ) : (
                                    <p className="text-red mb-5">You are not authorized to create projects.</p>
                                )}
                            </div>
                            <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {loading ? (
                                    <p className="text-white-default">Loading projects...</p>
                                ) : (
                                    projects.map((project, index) => (
                                        <div key={index}
                                             className={`bg-dark-300 h-32 p-4 rounded-md shadow-md border-t-4 cursor-pointer hover:scale-[101.5%] transition ease-in-out duration-200`}
                                             style={{ borderColor: project.color ? project.color : '#7e5bef' }}>
                                            <h3 className="text-lg text-white-default font-semibold mb-2">{project.name}</h3>
                                            <p className="text-sm text-dark-500 overflow-hidden overflow-ellipsis w-100 ">{project.description}</p>
                                        </div>
                                    ))
                                )}
                                {projects.length === 0 && (
                                    <div className="bg-gray-100 p-2 rounded-md ">
                                        <p className="text-white-default">No projects found for this organization.</p>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {activeTab === 'settings' && (
                        <div>
                            {/* Display organization settings  can delete the org if is owner, update name and description of owner or editior and get new api keys if they are owner or editor*/}
                            <h2 className="text-xl font-semibold mb-4 text-white-default">Organization Settings</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div
                                    className="bg-dark-300 h-auto p-4 rounded-md shadow-md transition ease-in-out duration-200 flex items-center justify-between">
                                    <div>
                                        <h3 className="text-lg text-white-default font-semibold mb-2">Organization
                                            Name</h3>
                                        <p className="text-sm text-dark-500 overflow-hidden overflow-ellipsis w-100">{org.orgName}</p>
                                    </div>
                                    <button
                                        onClick={() => setCreateProjectModalIsOpen(true)}
                                        className="inline-flex mb-5 bg-purple hover:bg-card-purple text-white-default py-2 px-5 rounded transition ease-in-out duration-100"
                                    >
                                        <PlusIcon className="h-6 w-5 mr-1" />
                                        Change Name
                                    </button>
                                </div>
                                <div
                                    className="bg-dark-300 h-auto p-4 rounded-md shadow-md transition ease-in-out duration-200 flex items-center justify-between">
                                    <div>
                                        <h3 className="text-lg text-white-default font-semibold mb-2">Organization
                                            Description</h3>
                                        <p className="text-sm text-dark-500 overflow-hidden overflow-ellipsis w-100">{org.orgDesc}</p>
                                    </div>
                                    <button
                                        onClick={() => setCreateProjectModalIsOpen(true)}
                                        className="inline-flex mb-5 bg-purple hover:bg-card-purple text-white-default py-2 px-5 rounded transition ease-in-out duration-100"
                                    >
                                        <PlusIcon className="h-6 w-5 mr-1" />
                                        Change Description
                                    </button>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div
                                    className="bg-dark-300 h-auto p-4 rounded-md shadow-md transition ease-in-out duration-200 mt-4">
                                    <h3 className="text-lg text-white-default font-semibold mb-2">API Key</h3>
                                    <div className="flex items-center justify-between">
                                        <input
                                            type="text"
                                            value={org.api_key}
                                            readOnly
                                            className="text-sm text-dark-500 bg-dark-200 p-2 rounded w-full mr-2"
                                        />
                                        <button
                                            onClick={() => copyApiKey(org.api_key)}
                                            className="inline-flex bg-purple hover:bg-card-purple text-white-default py-2 px-5 rounded transition ease-in-out duration-100"
                                        >
                                            Copy
                                        </button>
                                    </div>
                                    <button
                                        onClick={generateNewApiKey}
                                        className="inline-flex mt-3 bg-purple hover:bg-card-purple text-white-default py-2 px-5 rounded transition ease-in-out duration-100"
                                    >
                                        Generate New API Key
                                    </button>
                                </div>
                                <div
                                    className="bg-dark-300 h-auto p-4 rounded-md shadow-md transition ease-in-out duration-200 mt-4">
                                    <h3 className="text-lg text-white-default font-semibold mb-2">Join Code</h3>
                                    <div className="flex items-center justify-between">
                                        <input
                                            type="text"
                                            value={org.joinCode}
                                            readOnly
                                            className="text-sm text-dark-500 bg-dark-200 p-2 rounded w-full mr-2"
                                        />
                                        <button
                                            onClick={() => copyJoinCode(org.joinCode)}
                                            className="inline-flex bg-purple hover:bg-card-purple text-white-default py-2 px-5 rounded transition ease-in-out duration-100"
                                        >
                                            Copy
                                        </button>
                                    </div>
                                    <button
                                        onClick={generateNewJoinCode}
                                        className="inline-flex mt-3 bg-purple hover:bg-card-purple text-white-default py-2 px-5 rounded transition ease-in-out duration-100"
                                    >
                                        Generate New Join Code
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {activeTab === 'members' && (
                        <div>
                            <h2 className="text-xl font-semibold mb-4 text-white-default">Members</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:w-[70vw] xl:grid-cols-3 gap-4">
                                {orgMembers.map((member, index) => (
                                    <div key={index}
                                         className="bg-dark-300 h-auto p-4 rounded-md shadow-md transition ease-in-out duration-200 flex items-center">
                                        <div className="flex-shrink-0 mr-4">
                                            {/* Display profile picture if available */}
                                            {member.profilePicture ? (
                                                <img className="h-12 w-12 rounded-full" src={member.profilePicture}
                                                     alt="Profile Picture" />
                                            ) : (
                                                <div className="h-12 w-12 bg-gray-400 rounded-full"></div>
                                            )}
                                        </div>
                                        <div className="flex-grow">
                                            <div>
                                                <h3 className="text-lg text-white-default font-semibold mb-2">{member.memberName}</h3>
                                                <p className="text-sm text-dark-500 overflow-hidden overflow-ellipsis w-100">{member.memberRole}</p>
                                            </div>
                                            {
                                                (member.memberID === user.uid ? (
                                                        <p className="text-red-500">You are not authorized to edit this member.</p>
                                                    ) : (

                                                        ((userRole === 'Owner' || userRole === "Editor") && member.memberRole !== "Owner"  ? (
                                                                <div className="mt-2">
                                                                    <label htmlFor={`role-${index}`} className="block text-sm font-medium text-dark-500 mr-2 mb-3">Change Role:</label>
                                                                    <div className="inline-flex">
                                                                        <select
                                                                            id={`role-${index}`}
                                                                            name={`role-${index}`}
                                                                            className="block cursor-pointer appearance-none border border-dark-300 bg-dark-100 text-dark-700 py-2 px-3 rounded-md mr-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                                                                            value={selectedRole}
                                                                            onChange={handleRoleChange}
                                                                        >
                                                                            <option value="Member">Member</option>
                                                                            <option value="Editor">Editor</option>
                                                                        </select>
                                                                        <button
                                                                            onClick={() => handleUpdateRole(member.id, selectedRole)}
                                                                            className="py-2 px-4 bg-purple hover:bg-card-purple text-white-default rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                        >
                                                                            Update
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <p className="text-red-500">You are not authorized to edit this member.</p>
                                                            )
                                                        ))
                                                )
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Modal
                isOpen={createProjectModalIsOpen}
                onRequestClose={() => setCreateProjectModalIsOpen(false)}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Create Organization Modal"
            >
                <h2 className="text-lg font-semibold mb-4 text-gray-800">Create Project</h2>
                <button onClick={() => setCreateProjectModalIsOpen(false)}
                        className="absolute top-2 right-2 text-gray-600 hover:text-gray">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <div>
                    <label htmlFor="projectName" className="block text-sm font-medium text-gray-700">Project
                        Name:</label>
                    <input
                        type="text"
                        id="projectName"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        className="block w-full outline outline-2 outline-purple  p-3 mt-1 mb-5 bg-transparent rounded-md text-white-default focus:outline-text-lightPurple focus:border-none transition ease-in-out duration-300"
                        placeholder="Enter project name"
                        maxLength={50}
                        required // Add required attribute
                    />
                    <label htmlFor="projectDesc" className="block text-sm font-medium text-gray-700">Project
                        Description:</label>
                    <input
                        type="text"
                        id="projectDesc"
                        value={projectDescription}
                        onChange={(e) => setProjectDescription(e.target.value)}
                        className="block w-full outline outline-2 outline-purple  p-3 mt-1 mb-5 bg-transparent  rounded-md text-white-default focus:outline-text-lightPurple focus:border-none transition ease-in-out duration-300"
                        placeholder="Enter project description"
                        maxLength={50}
                        required // Add required attribute
                    />
                    { /* Conditional rendering for prompt message */}
                    {(projectName.trim() === '' || projectDescription.trim() === '') && (
                        <p className="text-red-500">All input fields are required.</p>
                    )}
                    <button
                        onClick={handleCreateProject}
                        disabled={projectName.trim() === '' || projectDescription.trim() === ''}
                        className="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 transition ease-in-out duration-300"
                    >
                        Create Project
                    </button>
                </div>
            </Modal>
            <Notifications ref={notificationRef} />

        </div>
    );
}

export default OrganizationDisplay;

