import React from 'react';
import NavBar from "../NavBar";

const Subscribe = () => {
    return (
        <div className="bg-dark-main text-white-default min-h-screen font-sans">
            <NavBar />
            <section className="container mx-auto flex flex-col items-center py-20 lg:py-32">
                <div className="lg:w-2/3 text-center p-6">
                    <h1 className="text-5xl lg:text-6xl font-extrabold mb-4 leading-tight">Upgrade Your LogBug Experience</h1>
                    <p className="text-lg lg:text-xl text-gray-400 mb-6 leading-relaxed">
                        Enhance your productivity with our advanced features. Choose the plan that best suits your needs.
                    </p>
                </div>

                <div className="w-full lg:w-3/2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
                    <div className="bg-dark-100 p-6 rounded-lg shadow-md flex flex-col items-center justify-between">
                        <div className="flex flex-col items-center">
                            <h2 className="text-2xl font-bold mb-4">Free Plan</h2>
                            <p className="text-lg text-gray-400 mb-4">Essential features for individuals.</p>
                            <p className="text-4xl font-extrabold mb-4">£0<span className="text-xl text-gray-400">/mo</span></p>
                            <ul className="list-disc list-inside text-gray-400 mb-6">
                                <li>1 Organization Limit</li>
                                <li>3 Project Limit</li>
                                <li>3 Kanban Boards</li>
                                <li>5 Team Members</li>
                            </ul>
                        </div>
                        <button className="px-14 py-2 bg-purple text-white hover:bg-card-purple transition rounded-md">
                            Subscribe
                        </button>
                    </div>

                    <div className="bg-dark-100 p-6 rounded-lg shadow-md flex flex-col items-center justify-between">
                        <div className="flex flex-col items-center">
                            <h2 className="text-2xl font-bold mb-4">Basic Plan</h2>
                            <p className="text-lg text-gray-400 mb-4">Essential features for small teams.</p>
                            <p className="text-4xl font-extrabold mb-4">£4.99<span className="text-xl text-gray-400">/mo</span></p>
                            <ul className="list-disc list-inside text-gray-400 mb-6">
                                <li>Custom Project Colours</li>
                                <li>5 Organization Limit</li>
                                <li>10 Project Limit</li>
                                <li>10 Kanban Boards</li>
                                <li>15 Team Members</li>
                            </ul>
                        </div>
                        <button className="px-14 py-2 bg-purple text-white hover:bg-card-purple transition rounded-md">
                            Subscribe
                        </button>
                    </div>

                    <div className="bg-dark-100 p-6 rounded-lg shadow-md flex flex-col items-center justify-between">
                        <div className="flex flex-col items-center">
                            <h2 className="text-2xl font-bold mb-4">Pro Plan</h2>
                            <p className="text-lg text-gray-400 mb-4">Advanced features for growing teams.</p>
                            <p className="text-4xl font-extrabold mb-4">£9.99<span className="text-xl text-gray-400">/mo</span></p>
                            <ul className="list-disc list-inside text-gray-400 mb-6">
                                <li>Everything in Basic</li>
                                <li>API Access</li>
                                <li>Unlimited Organization & Project Limit</li>
                                <li>Unlimited Kanban Boards</li>
                                <li>Unlimited Team Members</li>
                                <li>Advance Role Controls</li>
                                <li>Priority Customer Support</li>
                            </ul>
                        </div>
                        <button className="px-14 py-2 bg-purple text-white hover:bg-card-purple transition rounded-md">
                            Subscribe
                        </button>
                    </div>

                    <div className="bg-dark-100 p-6 rounded-lg shadow-md flex flex-col items-center justify-between md:col-start-2">
                        <div className="flex flex-col items-center">
                            <h2 className="text-2xl font-bold mb-4">Enterprise Plan</h2>
                            <p className="text-lg text-gray-400 mb-4">Custom solutions for large organizations.</p>
                            <p className="text-4xl font-extrabold mb-4">Contact us</p>
                            <ul className="list-disc list-inside text-gray-400 mb-6">
                                <li>Everything in Pro</li>
                                <li>Dedicated support</li>
                                <li>Daily Backups</li>
                                <li>Custom integrations</li>
                            </ul>
                        </div>
                        <button className="px-14 py-2 bg-purple text-white hover:bg-card-purple transition rounded-md">
                            Contact Sales
                        </button>
                    </div>
                </div>
            </section>

            <footer className="py-8 bg-dark-200 text-center">
                <p className="text-gray-500">&copy; 2024 LogBug. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default Subscribe;
