import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuIcon } from '@heroicons/react/solid';

const NavBar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="container text-white-default mx-auto p-4 flex justify-between items-center">
            <h1 className="text-3xl font-extrabold">LogBug</h1>
            <nav className="hidden lg:flex space-x-10 mx-auto">
                <Link to="/dashboard" className="text-gray-400 hover:text-white transition">Dashboard</Link>
                <Link to="/organizations" className="text-gray-400 hover:text-white transition">Organizations</Link>
            </nav>
            <div className="flex items-center">
                <Link to="/logout" className=" px-4 py-2 border border-gray-400 text-gray-400 hover:bg-gray-600 hover:text-white transition rounded-md">Logout</Link>
                <button onClick={toggleMenu} className="text-gray-400 hover:text-white transition lg:hidden ml-4">
                    {menuOpen ?
                        <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
                        </svg> : <MenuIcon className="w-6 h-6" />}
                </button>
            </div>
            {menuOpen && (
                <nav className="lg:hidden bg-dark-200 p-4 space-y-4 mt-4">
                    <Link to="/dashboard" className="block text-gray-400 hover:text-white transition">Dashboard</Link>
                    <Link to="/organizations" className="block text-gray-400 hover:text-white transition">Organizations</Link>
                </nav>
            )}
        </header>
    );
};

export default NavBar;
