import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCSqDh7qkhEfDD6SOGjtZ4MxFsdnOMev4k",
    authDomain: "logbug-c66f4.firebaseapp.com",
    projectId: "logbug-c66f4",
    storageBucket: "logbug-c66f4.appspot.com",
    messagingSenderId: "114366679176",
    appId: "1:114366679176:web:a39b7dcf1cc02634c7f73d",
    measurementId: "G-0VJ0275RR8"
};

firebase.initializeApp(firebaseConfig);


export const auth = firebase.auth();
export const db = firebase.firestore();
export const provider = new firebase.auth.GoogleAuthProvider();
