import React, { useState } from 'react';
import './AuthPage.css';
import { supabase } from './supabaseClient'; // Supabase configuration
import { auth, provider } from './firebaseConfig';
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    updateProfile,
    GoogleAuthProvider,
    signInWithPopup,
} from 'firebase/auth';
import { Link } from 'react-router-dom';

const AuthPage = () => {
    const [isLogin, setIsLogin] = useState(true);

    const toggleAuthType = () => {
        setIsLogin(!isLogin);
    };

    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            // Check if the user document already exists in supabase database not firebase
            const { data: userDoc, error } = await supabase
                .from('users')
                .select('*')
                .eq('UID', user.uid)
                .single();

            // If the user document doesn't exist, create it
            if (!userDoc) {
                await supabase.from('users').insert([
                    {
                        UID: user.uid,
                        membershipType: 'free',
                    },
                ]);
            }

            window.location.href = '/dashboard';
        } catch (error) {
            console.error('Error during Google Sign In', error);
        }
    };

    const handleAuth = async (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;

        try {
            if (isLogin) {
                const { user } = await signInWithEmailAndPassword(auth, email, password);

                if (!user.emailVerified) {
                    await sendEmailVerification(auth.currentUser);
                    alert('Verification email sent. Please verify your email before proceeding.');
                } else {
                    window.location.href = '/dashboard';
                }
            } else {
                const username = e.target.username.value;
                const { user } = await createUserWithEmailAndPassword(auth, email, password);
                await updateProfile(user, { displayName: username });

                const { data: userDoc, error } = await supabase
                    .from('users')
                    .select('*')
                    .eq('UID', user.uid)
                    .single();

                if (!userDoc) {
                    await supabase.from('users').insert([
                        {
                            UID: user.uid,
                            membershipType: 'free',
                        },
                    ]);
                }

                await sendEmailVerification(auth.currentUser);
                alert('Account created. Verification email sent. Please verify your email before proceeding.');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div className="bg-dark-main text-white-default min-h-screen font-sans flex flex-col">
            <header className="container mx-auto p-4 flex justify-between items-center">
                <h1 className="text-2xl font-extrabold">LogBug</h1>
            </header>

            <section className="container mx-auto flex flex-col items-center pb-5">
                <div className="lg:w-2/3 text-center p-4">
                    <h1 className="text-4xl lg:text-5xl font-extrabold mb-3 leading-tight">
                        {isLogin ? 'Welcome Back to LogBug' : 'Join LogBug Today'}
                    </h1>
                    <p className="text-md lg:text-lg text-gray-400 mb-4 leading-relaxed">
                        {isLogin
                            ? 'Please login to continue.'
                            : 'Create an account to start your journey with us.'}
                    </p>
                </div>

                <div className="w-full lg:w-1/2 bg-dark-100 p-4 rounded-lg shadow-md">
                    <div className="auth-toggle flex justify-center mb-4">
                        <button
                            className={`px-3 py-1 rounded-l-md hover:bg-text-lightPurple ${isLogin ? 'text-white bg-purple' : '   bg-card-purple text-gray-400'}`}
                            onClick={toggleAuthType}
                        >
                            Login
                        </button>
                        <button
                            className={`px-3 py-1 rounded-r-md  hover:bg-text-lightPurple ${!isLogin ? 'text-white bg-purple ' : ' bg-card-purple text-gray-400'}`}
                            onClick={toggleAuthType}
                        >
                            Sign Up
                        </button>
                    </div>

                    {isLogin ? (
                        <div>
                            <h2 className="text-xl font-bold mb-3">Member Login</h2>
                            <form onSubmit={handleAuth}>
                                <label htmlFor="email" className="block text-gray-400 mb-1">Email:</label>
                                <input type="email" className="text-dark-100 mb-3 w-full p-2 rounded-md" id="email" name="email" required />

                                <label htmlFor="password" className="block text-gray-400 mb-1">Password:</label>
                                <input type="password" className="text-dark-100 mb-3 w-full p-2 rounded-md" id="password" name="password" required />

                                <button className="px-6 py-2 bg-purple text-white rounded-md hover:bg-card-purple ease-in-out transition focus:outline-none focus:ring-2 focus:ring-primary">
                                    Login
                                </button>
                            </form>

                            <hr className="break-bar my-8"></hr>

                            <div className="google-sign-in">
                                <button
                                    onClick={handleGoogleSignIn}
                                    className="px-4 py-2 bg-red text-white hover:bg-dark-red transition rounded-md w-full"
                                >
                                    Sign Up with Google
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h2 className="text-xl font-bold mb-3">Sign Up</h2>
                            <form onSubmit={handleAuth}>
                                <label htmlFor="username" className="block text-gray-400 mb-1">Username:</label>
                                <input type="text" className="flex-1 px-4 py-2 border border-gray-600 bg-dark-main text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary" id="username" name="username" required />

                                <label htmlFor="name" className="block text-gray-400 mb-1">Name:</label>
                                <input type="text" className="flex-1 px-4 py-2 border border-gray-600 bg-dark-main text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary" id="name" name="name" required />

                                <label htmlFor="emailSignUp" className="block text-gray-400 mb-1">Email:</label>
                                <input type="email" className="flex-1 px-4 py-2 border border-gray-600 bg-dark-main text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary" id="emailSignUp" name="email" required />

                                <label htmlFor="passwordSignUp" className="block text-gray-400 mb-1">Password:</label>
                                <input type="password" className="flex-1 mb-4 px-4 py-2 border border-gray-600 bg-dark-main text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary" id="passwordSignUp" name="password" required />

                                <button className="px-6 py-2 bg-purple text-white rounded-md hover:bg-card-purple ease-in-out transition focus:outline-none focus:ring-2 focus:ring-primary">
                                    Sign Up
                                </button>
                            </form>

                            <hr className="break-bar my-8"></hr>

                            <div className="google-sign-in">
                                <button
                                    onClick={handleGoogleSignIn}
                                    className="px-4 py-2 bg-red text-white hover:bg-dark-red transition rounded-md w-full"
                                >
                                    Sign Up with Google
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <footer className="py-6 bg-dark-200 text-center">
                <p className="text-gray-500">&copy; 2024 LogBug. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default AuthPage;
